import React from 'react';
import "./footer.css";
import {Container,Col} from "reactstrap";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';

const Footer = ({pageData}) => {
    const {t , i18n} = useTranslation()
    return (
        <div className='footer-container'>
            <footer>
                <div className="footer-wrap">
                    <section className='about-company' >
                        <div className="footer_logo">
                            <img src={pageData.logo} alt='logo' className="img-fluid"/>
                        </div>
                        <p className="footer_text mt-4">{pageData.description}</p>
                    </section>


                    <section className='footer_contact'>
                            <ul className="footer_center">
                                <li className="ps-0 border-0 d-flex align-items-center gap-2">
                                    <span><i className="ri-map-pin-line"></i></span>
                                    <label>{pageData.address}</label>
                                </li>
                                <li className="ps-0 border-0 d-flex align-items-center gap-2">
                                    <span><i className="ri-phone-line"></i></span>
                                    <label>{pageData.phone}</label>
                                </li>
                                <li className="ps-0 border-0 d-flex align-items-center gap-2">
                                    <span><i className="ri-mail-line"></i></span>
                                    <label>{pageData.email}</label>
                                   
                                </li>
                            </ul>
                    </section>

                    <section className='footer_social' >
                        <a href='https://www.facebook.com/profile.php?id=61552573501413' target='_blank'>
                            <span><Icon icon="logos:facebook" /></span>
                        </a>
                        <a href='https://instagram.com/artwork_furniture_am?igshid=NGVhN2U2NjQ0Yg==' target='_blank'>
                            <span><Icon icon="skill-icons:instagram" /></span>
                        </a>           
                    </section>
                </div>
            </footer>
        </div>
    );
};

export default Footer;