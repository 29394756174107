import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "remixicon/fonts/remixicon.css";
import "bootstrap/dist/css/bootstrap.css";
import { BrowserRouter } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider } from "react-redux";
import store from "./redux/store";
import App from "./App";
import './i18n';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { RotatingLines } from  'react-loader-spinner';
import "./App.css";


const rootElement = document.getElementById("root");
// Wrap the event listener in a function to prevent potential issues
const reloadOnBeforeUnload = () => {
  window.location.reload('/');
};

// Attach the event listener
window.addEventListener("beforeunload", reloadOnBeforeUnload);

const renderApp = () => {
  ReactDOM.hydrate(
    <React.StrictMode>
      <BrowserRouter basename="/">
        <Provider store={store}>
        <ToastContainer
position="top-right"
autoClose={1000}
closeOnClick
theme="light"
/>

          <Suspense fallback={<div className="Loading" >
            <RotatingLines
  strokeColor="grey"
  strokeWidth="5"
  animationDuration="0.75"
  width="96"
  visible={true}
/></div>}>
            <App />
          </Suspense>
        </Provider>
      </BrowserRouter>
    </React.StrictMode>,
    rootElement
  );
};

renderApp();
