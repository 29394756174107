import React, { useRef, useEffect, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import './Header.css';
import { Container, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { MdHome } from "react-icons/md";
import { FaShoppingCart } from "react-icons/fa";
import Picker from '../picker/Picker';
import MobMenu from '../MobMenu/MobMenu';

const Header = ({ setSelectedLanguage, pageData }) => {

  const { t, i18n } = useTranslation()
  const [isOpen, setOpen] = useState(false);

  const nav_links = [
    {
      path: '/',
      display: t("home"),
      icon: <MdHome/>
    },
    {
      path: 'cart',
      display: t("purchasedProducts"),
      icon: <FaShoppingCart/>
    },
  ];


  const handleLanguageChange = (language) => {
    // setSelectedLanguage(language)
    i18n.changeLanguage(language)
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const totalQuantity = useSelector((state) => state.cart.totalQuantity);
  const menuRef = useRef(null);
  const [headerConfig, setHeaderConfig] = useState(null)
  const navigate = useNavigate();

  const headerStckyFunck = () => {
    window.addEventListener('scroll', () => {
      if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
        setHeaderConfig(true)
      } else {
        setHeaderConfig(false)
      }
    });
  };

  useEffect(() => {
    headerStckyFunck();
    return () => window.removeEventListener('scroll', headerStckyFunck);
  });

  const navigateToCart = () => {
    navigate('/cart');
  };

  useEffect(() => {
    const header = document.querySelector('header');

   if(!isOpen){
      header.style.height = '80px'
   } else {
      header.style.height = '270px'
   }
  }, [isOpen])
  

  return (
    <header>
          <div className="nav_wrapper">
            <Link to="/">
                <div className="Logo">
                  <img src={require('../../assets/images/logo.png')}  />
              </div>
            </Link>

            <ul className="navigation" ref={menuRef} >
                {nav_links.map((item, index) => (
                  <li className="navItem" key={index}>
                    <NavLink
                      to={item.path}
                      className={(navClass) => (navClass.isActive ? 'nav_active' : '')}
                    >
                      {item.icon} {item.display}
                    </NavLink>
                  </li>
                ))}
            </ul>
            
            <div className="nav_icons">
              <span className="cart_icon" onClick={navigateToCart}>
                <FaShoppingCart/>
                <span className="badge">{totalQuantity}</span>
              </span>
              {/* <div className="language-bar">
               <Picker changeLang={handleLanguageChange} />
              </div> */}
              <MobMenu isOpen={isOpen} setOpen={setOpen} />
            </div>
          </div>
               <ul className="navigation-mob" ref={menuRef} >
                {nav_links.map((item, index) => (
                  <li className="navItem" key={index}>
                    <NavLink
                      onClick={()=>setOpen(false)}
                      to={item.path}
                      className={(navClass) => (navClass.isActive ? 'nav_active' : '')}
                    >
                      {item.icon} {item.display}
                    </NavLink>
                  </li>
                ))}
                <div className="language-bar-mob">
               <Picker changeLang={handleLanguageChange} />
                </div>
            </ul>

            
    </header>
  );
};

export default Header;
