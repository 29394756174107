import React from 'react'
import './Loading.css'
import ClipLoader from 'react-spinners/ClipLoader'

export default function Loading() {
  return (
    <div className='loadinContainer'>
       <ClipLoader
          color="#9c815a"
          loading
        />
    </div>
  )
}
