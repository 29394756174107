import React, { useEffect } from "react";
import "../styles/cart.css";
import Helmet from "./../Components/Helmet/Helmet";
import { Container, Row, Col,} from "reactstrap";
import { cartActions } from "../redux/slices/cartSlice";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaArrowLeft } from "react-icons/fa";

const Cart = ({ host }) => {



  const { t } = useTranslation();
  const cartItems = useSelector((state) => state.cart.cartItems);




  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

 


  return (
    <section className="cart-container" >
      <Container>
        <Row className="containerInfo">
          <Col lg="9">
            {cartItems.length === 0 ? (
              <h2 className="fs-4 text-center ">{t("cart_title")}</h2>
            ) : (
              <table className="table bordered">
                <thead>
                  <tr>
                    <th>{t("table_image")}</th>
                    <th>{t("table_name")}</th>
                    <th>{t("table_price")}</th>
                    <th>{t("table_count")}</th>
                    <th>{t("remove")}</th>
                  </tr>
                </thead>
                <tbody>
                  {cartItems.map((item, index) => (
                    <Tr item={item} key={index} />
                  ))}
                </tbody>
              </table>
            )}
          </Col>
          <Row>
          {cartItems.length === 0 ? (
              <></>
            ) : ( <div className="buyBtn">
                <Link to="/productorder">
                  <button className="buy_btn w-100">
                    {t("buy_btn")}
                  </button>
                </Link>
              </div>
              )}
            <div className="sub_nav" >
              <Link to={'/'} className="txtBg" onClick={()=>{
                setTimeout(() => {
                  window.scrollTo({top: 370})
                }, 500);
              }} ><FaArrowLeft/> Կատարել Գնումներ</Link>
            </div>
          </Row>
          {/* sharunakel gnumner@ */}
          <Col lg="10">
            <div className="cartConter">
            <div className="info">
            <h3>{t("warning_title")}</h3>
            <p>{t("warning_description")}</p>
          </div>
             
            </div>
          </Col>
        </Row>
      </Container>
    </section>    
  );
};

const Tr = ({ item }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const deleteProduct = () => {
    dispatch(cartActions.deleteItem(item.id));
  };
  
  return (
    <tr>
      <td>
        <img src={item.imgUrl} alt="image" />
      </td>
      <td>{item.productName}</td>
      <td>{item.price}0 ֏</td>
      <td>
        {t("items_count")} {item.quantity}
      </td>
      <td>
        <i onClick={deleteProduct} className="ri-delete-bin-line"></i>
      </td>
    </tr>
  );
};

export default Cart;
