import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import Loading from "./loadingAnimation/Loading";
import '../styles/zal.css'
import { motion } from "framer-motion";

const OtherSubCategories = ({ selectedLanguage, host }) => {
  const { subcategoryId } = useParams();
  const [otherCategories, setOtherCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true)
    const apiUrl = `${host}/api/v1/subcategories/${subcategoryId}/othercategories/${selectedLanguage}`;

    axios
      .get(apiUrl)
      .then((response) => {
        setOtherCategories(response.data);
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        console.error("Error fetching Other Categories data:", error);
      });
  }, [subcategoryId, selectedLanguage]);

  return (
    <>
      {
        loading ? <Loading/> :
        <div className="Categories_container">
          <div className="list-title">
            <h1>ԸՆՏՐԵՔ ԿԱՀՈՒՅՔ</h1>
            </div>
          <section className="containerCategories">
            {otherCategories
              .filter(item => item.is_published)
              .map((item,index) => (
                <Link to={`/products/${subcategoryId}/${item.id}`} key={item.id}>
                  <motion.div
                   className="infoLinkConter"
                   initial={{opacity: 0, scale: 0.9}}
                   whileInView={{opacity: 1,scale:1}}
                   transition={{duration: 0.6,delay: 0.1*index,ease: 'easeInOut'}}
                   >
                      <img src={item.image} alt="image" />
                      <h3>{item.other_sub_name}</h3>
                  </motion.div>
                </Link>
              ))}
          </section>
        </div>
      }
    </>
  );
};

export default OtherSubCategories;
