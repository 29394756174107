import React, { useEffect, useState } from "react";
import { Container, Col } from 'reactstrap';
import '../services/Services.css';
import { Link } from "react-router-dom";
import Loading from "../Components/loadingAnimation/Loading";
const Services = ({ selectedLanguage, host }) => {
  const [serviceData, setServiceData] = useState([]);
  const [loading, setLoading] = useState(true);

  const apiUrl = `${host}/api/v1/categories/${selectedLanguage}`;
  const fetchData = async () => {
    try {
      const response = await fetch(apiUrl);
      setLoading(false)
      if (response.ok) {
        const jsonData = await response.json();
        setServiceData(jsonData);
      } else {
        console.error('Error fetching data from the API');
      }
    } catch (error) {
      setLoading(false)
      console.error('Error:', error);
    }
  }
  useEffect(() => {
    fetchData();
  }, [selectedLanguage]);

  return (
    <>
      {
        loading ? <Loading /> :
          <section className="services">
            {serviceData
              .filter(item => item.is_published)
              .map((item, index) => (
                <Link to={`/${item.category}/${item.id}`} key={index}>
                  <div className="services_item">
                    <h1 className="beforeHoverTitle" >{item.title}</h1>
                    <img src={item.imgUrl} alt="service" />
                    <h1 className="service_title" >{item.title}  <div className="service_sub" >{item.subtitle}</div></h1>

                  </div>
                </Link>
              ))}
          </section>
      }
    </>
  );
}

export default Services;