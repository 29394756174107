import Carousel from 'react-bootstrap/Carousel';
import './Slider.css'
import { useEffect, useState } from 'react';
import MediaQuery, { useMediaQuery } from 'react-responsive'
import { CarouselIndicators } from 'reactstrap';

function Slider({data}) {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' })
  const [indexItem, setIndexItem] = useState(0);

  useEffect(() => {
    let length = data.filter(item => item.url).length;
    const next = document.querySelector('.carousel-control-next-icon');
    const prev = document.querySelector('.carousel-control-prev-icon');

   if(next){
    next.addEventListener('click',()=>{
      setIndexItem(prevIndex => (prevIndex+1)%length)
    });
   }

    if(prev){
      prev.addEventListener('click', () => {
        setIndexItem(prevIndex => prevIndex === 0 ? length - 1 : (prevIndex - 1) % length);
      });
    }
    
  }, [])
  

  const handleSelect = (selectedIndex) => {
    console.log(selectedIndex);
    setIndexItem(selectedIndex);
  };
  return (
    <div className="mainSliderContainer">
      <Carousel 

        activeIndex={indexItem}
        slide={false}  
        indicators={false} 
        controls={isTabletOrMobile ? false : true} 
        interval={null} 
        variant="dark" 
        className='slider-container'
        touch={false}
      >
        {
          data && data.map(item => {
            if(item.url){
                  return (<Carousel.Item>
                      <img width={200} src={item.url} />
              </Carousel.Item>)
            }
          })
        }
      </Carousel>
      {/* <MediaQuery maxWidth={1024}> */}
          <ul className='list-indicator'>
            {
                data && data.map((item,index) =>item.url && <li style={{opacity: index===indexItem ? 1 : 0.4}} onClick={()=>handleSelect(index)}><img src={item.url} /></li>)
            }
          </ul>
      {/* </MediaQuery> */}
    </div>
  );
}

export default Slider;