import React, { useEffect, useState } from "react";
import "../styles/cart.css";
import Helmet from "./../Components/Helmet/Helmet";
import { Container, Row, Col, Form, FormGroup } from "reactstrap";
import { useSelector } from "react-redux";
import axios from "axios";
import {  toast } from 'react-toastify';

import { useTranslation } from "react-i18next";

const Productorder = ({ host }) => {



  const { t } = useTranslation();
  const cartItems = useSelector((state) => state.cart.cartItems);
  const totalAmount = useSelector((state) => state.cart.totalAmount);
  const totalQty = cartItems.length;

  const [callDiv, setCallDiv] = useState();
  const [divType, setDivType] = useState(false);

  const [name, setName] = useState("");
  const [email] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [city, setCity] = useState("");
  const [shippingAddress, setShippingAddress] = useState("");

  const handleCheckout = async () => {
    const checkoutData = {
      name: name,
      email: email,
      phone_number: phoneNumber,
      city: city,
      shipping_address: shippingAddress,
    };

    const dataCartInfo = cartItems.map((item) => ({
      productName: item.productName,
      imgUrl: item.imgUrl,
      price: item.price,
      quantity: item.quantity,
    }));

    try {
      const response = await axios.post(
        "https://backend.artwork.am/api/v1/checkout_and_cart/",
        {
          checkoutData: JSON.stringify(checkoutData),
          cartData: JSON.stringify(dataCartInfo),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setCallDiv(true);
      setDivType(!divType);
      setInterval(() => {
        window.location.href = "/";
      }, 2000);
    } catch (error) {
      console.error(error);
      setCallDiv(false);
      setDivType(!divType);
    }
    toast.success(t("delivery_call_title"))
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

 


  return (
    <Helmet>
   
   <section className="ProductAOrder">
      <Container>
        <Row>
          <Col className="ProductAOrderContent" lg="8">
          <div className="info">
            <h3>{t("warning_title")}</h3>
            <p>{t("warning_description")}</p>
          </div>
            <h6 className="mb-4 fw-bold">{t("buy_info")}</h6>
            <Form className="billing__form">
              <FormGroup className="form_group">
                <input
                  type="text"
                  placeholder={t("form_name")}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </FormGroup>
            
              <FormGroup className="form_group">
                <input
                  type="number"
                  placeholder={t("form_phone")}
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </FormGroup>
              <FormGroup className="form_group">
                <input
                  type="text"
                  placeholder={t("form_city")}
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </FormGroup>
              <FormGroup className="form_group">
                <input
                  type="text"
                  placeholder={t("form_address")}
                  value={shippingAddress}
                  onChange={(e) => setShippingAddress(e.target.value)}
                />
              </FormGroup>
            </Form>

          
            <div
              className={divType ? "visible-message" : "unvisible-message"}
            >
              <p className={callDiv ? "text-green" : "text-red"}>
                {callDiv
                  ? t("delivery_call_title")
                  : t("delivery_call_error_title")}
              </p>
            </div>

            <div className="checkout_cart">
              <h6>
                {t("items_count")}:{" "}
                <span>
                  {totalQty} {t("items")}
                </span>
              </h6>
              <h6>
                <span>{t("delivery_title")}</span>{" "}
              </h6>
              <h4>
                {t("items_count")}: <span>{totalAmount}.000 ֏</span>
              </h4>
              <br></br>
              <button
                className="buy_btn auth_btn w-100"
                onClick={handleCheckout}
              >
                {t("buy_btn")}
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  </Helmet>
  );
};


  


export default Productorder;
