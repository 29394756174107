import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { cartActions } from "../redux/slices/cartSlice";
import '../styles/product-card.css';
import {  toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Loading from "./loadingAnimation/Loading";
import { CiDiscount1 } from "react-icons/ci";

const Products = ({ host , selectedLanguage }) => {
  const { subcategoryId, itemId } = useParams();
  const [products, setProducts] = useState([]);
  const [otherCategories, setOtherCategories] = useState([]);
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [isMouseOver, setIsMouseOver] = useState(false);

  useEffect(() => {
    setLoading(true);
    const apiUrl = `${host}/api/v1/products/by-subcategory/${itemId}/${selectedLanguage}`;
    axios
      .get(apiUrl)
      .then((response) => {
        setProducts(response.data);
        setLoading(false);
        const list = document.getElementById('getListPosition');
        if(list){
          list.scrollIntoView({ behavior: 'smooth' });
          var storedScrollPosition = localStorage.getItem('scrollPosition');
          if (storedScrollPosition !== null) {
              window.scrollTo(0, parseInt(storedScrollPosition));
              localStorage.removeItem('scrollPosition');
          }
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [itemId, selectedLanguage]);

  useEffect(() => {
    setLoading(true);
    const apiUrl = `${host}/api/v1/subcategories/${subcategoryId}/othercategories/${selectedLanguage}`;

    axios
      .get(apiUrl)
      .then((response) => {
        setOtherCategories(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }, [subcategoryId, selectedLanguage]);

  
  const saveScrollPosition = () => {
    localStorage.setItem('scrollPosition', window.scrollY);
  }
  
  
    const scrolling = () => {
      const element = document.querySelector('.containerCategories_products')
      element.addEventListener('wheel', (event) => {
        event.preventDefault();
      
        element.scrollBy({
          left: event.deltaY < 0 ? -2 : 2,
        });
      });
     }
    
  return (
    <>
      {
        loading ? <Loading/> :
            <>
          <section 
            onWheel={scrolling}
            className="containerCategories_products"
          >
            {otherCategories
              .filter(item => item.is_published)
              .map(item => (
                <Link to={`/products/${subcategoryId}/${item.id}`} key={item.id}>
                  <div className="infoLinkConter">
                      <img src={item.image} alt="image" />
                    <h3>{item.other_sub_name}</h3>
                  </div>
                </Link>
            ))}
          </section>
          <section id="getListPosition" className="pt-0">
            <div className="list-wrapper">
                {products.map((item, index) => {
                  const addToCart = () => {
                    dispatch(
                      cartActions.addItem({
                        id: item.id,
                        productName: item.productName,
                        price: item.price,
                        imgUrl: item.imgUrl,
                      })
                    );
                    toast.success(t("Produqt_added"))
                    
                  };
                  return (
                    <>
                      {item.is_published ? (
                        <>
                          <div onClick={saveScrollPosition} className="product_item">
                        <Link to={`/shop/${item.id}`} >
                            <div className="product-image-container">
                                  <img src={item.imgUrl} alt="product-image" />
                            </div>  
                          </Link>

                            <div className="prodict-info">
                              <span>
                                <div>{item.productName}</div>
                                <div>{item.price}0֏</div>
                              </span>
                              <button onClick={addToCart}>Ավելացնել զամբյուղում</button>
                            </div>                          
                          </div>
                          </>
                      ) : null}
                    </>
                  );
                })}
                </div>
          </section>
        </>
      }
    </>
  );
};

export default Products;