import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Cart from '../pages/Cart';
import Home from '../pages/Home';
import SubCategories from "../Components/SubCategories";
import OtherCategories from "../Components/OtherCategories";
import Products from "../Components/Products";
import OtherSubcategoryProducts from "../Components/Products";
import DetailView from "../Components/DetailView";
import Productorder from "../pages/product_order_page";
const Routers = ({ selectedLanguage, host, pageData }) => {
    return (
        <Routes>
            <Route path="/" element={<Home host={host} pageData={pageData} selectedLanguage={selectedLanguage} />} />
            <Route path="cart" element={<Cart host={host}/>} />
            <Route path="productorder" element={<Productorder host={host}/>} />
            <Route path="/:category/:id" element={<SubCategories host={host} selectedLanguage={selectedLanguage} />} />
            <Route path="/othersubcategories/:subcategoryId" element={<OtherCategories host={host} selectedLanguage={selectedLanguage} />} />
            <Route exact path="/subcategories/:subcategoryId" element={<OtherSubcategoryProducts host={host} selectedLanguage={selectedLanguage} />} />
            <Route path="/products/:subcategoryId/:itemId" element={<Products host={host} selectedLanguage={selectedLanguage} />} />
            <Route path="/shop/:itemId" element={<DetailView host={host} selectedLanguage={selectedLanguage} />} />
        </Routes>
    )
}
export default Routers