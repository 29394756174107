import React, { useEffect, useState } from 'react';
import Header from "../Header/Header";
import Footer from "../../Components/Footer/Footer";
import Routers from '../../routers/Routers';
import Loading from '../loadingAnimation/Loading';

const Layout = () => {
  const host = "https://backend.artwork.am"
  const [selectedLanguage, setSelectedLanguage] = useState('am');
  const [pageData, setPageData] = useState([]);
  const [loading, setLoading] = useState(true);

  const apiUrl = `${host}/api/v1/page_default/${selectedLanguage}/`;
  const fetchData = async () => {
    try {
      setLoading(true)
      const response = await fetch(apiUrl);
      if (response.ok) {
        const jsonData = await response.json();
        setPageData(jsonData[0]);
      } else {
        console.error('Error fetching data from the API');
      }
    setLoading(false)
      
    } catch (error) {
      setLoading(false)
      console.error('Error:', error);   
    }
  }
  useEffect(() => {
    fetchData();
  }, [selectedLanguage]);

  function changeFavicon(newFaviconUrl, newIconUrl) {
    const link = document.querySelector("link[rel~='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = newFaviconUrl;
    document.getElementsByTagName('head')[0].appendChild(link);

    const linkApple = document.querySelector("link[rel='apple-touch-icon']") || document.createElement('link');
    linkApple.rel = 'apple-touch-icon';
    linkApple.href = newIconUrl;
    document.getElementsByTagName('head')[0].appendChild(linkApple);
  }

  const newFaviconUrl = pageData.logo;
  const newIconUrl = pageData.logo;

  changeFavicon(newFaviconUrl, newIconUrl);
  return (
    <>
      {
        loading ? <Loading/> : <>
          <Header selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} pageData={pageData} />
          <div>
            <Routers host={host} selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} pageData={pageData} />
          </div>
          <Footer pageData={pageData} />
        </>
      }
    </>
  );
};

export default Layout;