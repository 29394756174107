import React from 'react'
import { Sling as Hamburger } from 'hamburger-react'

export default function MobMenu({isOpen,setOpen}) {

  return (
    <div className='mob-menu-container' >
        <Hamburger color='var(--stroke-color)' toggled={isOpen} toggle={setOpen} />
    </div>
  )
}
