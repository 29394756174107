import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { cartActions } from "../redux/slices/cartSlice";
import "../styles/product-detalis.css";
import { toast } from "react-toastify";
import Loading from "./loadingAnimation/Loading";
import { IoIosArrowDown } from "react-icons/io";
import Slider from "./Slider/Slider";

const DetailView = ({ selectedLanguage, host }) => {
  const [select, setSelect] = useState(0);
  const sliderRef = useRef();
  const { t, i18n } = useTranslation();
  const { itemId } = useParams();
  const [item, setItem] = useState(null);
  const [tab, setTab] = useState(false);
  const reviewUser = useRef("");
  const reviewMsg = useRef("");
  const [reating, setReating] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const imageRef = useRef(null);

 

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [item]);
  
  useEffect(() => {
    setLoading(true);
    const apiUrlItem = `${host}/api/v1/products/${itemId}/${selectedLanguage}`;
    axios
      .get(apiUrlItem)
      .then((response) => {
        setItem(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching item data:", error);
      });
  }, [itemId, selectedLanguage]);

  useEffect(() => {
    setLoading(true);
    const apiUrlReviews = `${host}/api/v1/products/${itemId}/reviews/`;

    axios
      .get(apiUrlReviews)
      .then((response) => {
        setReviews(response.data);
      })
      .catch((error) => {
        console.error("Error fetching reviews:", error);
      });
  }, [itemId, selectedLanguage]);

  if (!item) {
    return <div>...</div>;
  }

  const productId = item.id;
  const filteredReviews = reviews.filter(
    (review) => review.product === productId
  );

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const userName = reviewUser.current.value;
    const rating = reating;
    const reviewText = reviewMsg.current.value;
    axios
      .post(
        `${host}/api/v1/products/reviews/`,
        {
          user_name: userName,
          rating: rating,
          text: reviewText,
          product: productId,
        }
      )
      .then((response) => {
        setInterval(() => {
          window.location.href = "/";
        }, 1500);
      })
      .catch((error) => {
        console.error("");
      });
    toast.success(t("Coment_added"));
  };

 
  const addToCart = () => {
    dispatch(
      cartActions.addItem({
        id: item.id,
        productName: item.productName,
        price: item.price,
        imgUrl: item.imgUrl,
      })
    );
    toast.success(t("Produqt_added"));
  };

  const ProductData = [
    // {
    //   type: 'video',
    //   url: item.video,
    // },
    {
      type: "img",
      url: item.imgUrlSecond,
    },
    {
      type: "img",
      url: item.imgUrlThird,
    },
    {
      type: "img",
      url: item.imgUrlFourth,
    },
    {
      type: "img",
      url: item.imgUrlFiveth,
    },
    {
      type: "img",
      url: item.imgUrlSixth,
    },
  ];

  const handleSelect = (index) => {
    setSelect(index);
  };

 

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="productDetailsContainer">
          <section className="section1">
              <div className="product_detalis">
                <div className="d-flex align-items-center gap-5">
                  <span className="product_price">{item.price}0 ֏</span>
                </div>
                <h2>{item.productName}</h2>
                <div className="product_rating d-flex align-items-center gap-5 mb-3">
                  <div>
                    <span>
                      <i className="ri-star-s-fill"></i>
                    </span>
                    <span>
                      <i className="ri-star-s-fill"></i>
                    </span>
                    <span>
                      <i className="ri-star-s-fill"></i>
                    </span>
                    <span>
                      <i className="ri-star-s-fill"></i>
                    </span>
                    <span>
                      <i className="ri-star-half-s-line"></i>
                    </span>
                  </div>
                  <p>
                    (<span>5</span> {t("rating_title")})
                  </p>
                </div>

                <p className="mt-3">{item.shortDesc}</p>
                <motion.button
                  whileTap={{ scale: 0.99 }}
                  className="buy_btn"
                  onClick={addToCart}
                >
                  {t("add_to_cart")}
                </motion.button>
              </div>
        
              <div className="ProductSliderContain">
                  <Slider data={ProductData}/>
              </div>
          </section>

          <section className="section2">
                  
                  <div className="about-product-container" >
                    <div  className={"list-title"} style={{marginBottom: 50, textAlign: 'center'}}>
                    <h1>{t("product_description")}</h1>
                  </div>
                    
                  <div className="tab_content mt-5">
                        <p>{item.description}</p>
                  </div> 

                  </div>

                  <div className="comments-section" >  
                  <div className="tab_wrapper">
                      <h6 onClick={() => setTab(!tab)}>
                        {t("review")} <label>({filteredReviews.length})</label> <IoIosArrowDown style={{transform: tab ? 'rotate(180deg)' : 'none'}}  />
                      </h6>
                  </div>

                  {tab &&  (
                    <div className="product_review mt-5">
                      <div className="review_wrapper">
                        <ul>
                          {filteredReviews.map((review, index) => (
                            <li key={index} className="mb-4">
                              <h6>{review.user_name}</h6>
                              <span>
                                {review.rating} ({t("rating_title")})
                              </span>
                              <p>{review.text}</p>
                            </li>
                          ))}
                        </ul>
                        <div className="review_form">
                          <h4>{t("review_title")}</h4>
                          <form onSubmit={handleFormSubmit}>
                            <div className="form_group">
                              <input
                                type="text"
                                placeholder={t("review_name")}
                                ref={reviewUser}
                                required
                              />
                            </div>
                            <div className="form_group rating_group">
                              <motion.span
                                whileTap={{ scale: 1.1 }}
                                onClick={() => setReating(1)}
                              >
                                1<i className="ri-star-s-fill"></i>
                              </motion.span>
                              <motion.span
                                whileTap={{ scale: 1.1 }}
                                onClick={() => setReating(2)}
                              >
                                2<i className="ri-star-s-fill"></i>
                              </motion.span>
                              <motion.span
                                whileTap={{ scale: 1.1 }}
                                onClick={() => setReating(3)}
                              >
                                3<i className="ri-star-s-fill"></i>
                              </motion.span>
                              <motion.span
                                whileTap={{ scale: 1.1 }}
                                onClick={() => setReating(4)}
                              >
                                4<i className="ri-star-s-fill"></i>
                              </motion.span>
                              <motion.span
                                whileTap={{ scale: 1.1 }}
                                onClick={() => setReating(5)}
                              >
                                5<i className="ri-star-half-s-line"></i>
                              </motion.span>
                              <label className="importantRatting" >{t("setReating")}</label>
                            </div>
                              <label style={{fontWeight: 'bold',fontSize: 'x-large',color: 'gold'}} >{reating}</label>
                            <div className="form_group">
                              <textarea
                                rows={4}
                                placeholder={t("review_description")}
                                ref={reviewMsg}
                                required
                              />
                            </div>
                            <motion.button
                              whileTap={{ scale: 0.99 }}
                              type="submit"
                              className="buy_btn"
                            >
                              {t("review_title")}
                            </motion.button>
                          </form>
                        </div>
                      </div>
                    </div>
                  )}
                  </div>
          </section>

        </div>
      )}
    </>
  );
};

export default DetailView;

