import "./App.css";
import Layout from './Components/Layut/Layout';
import { IoIosCall } from "react-icons/io";


function App() {

  const callToNumber = () =>{
    window.open('tel: 098871555')
  } 

  return(
   <>
   <IoIosCall onClick={callToNumber}  className="immendetly-call" />
  <Layout/>
   </>
  )
}

export default App;




