import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";
import Loading from "./loadingAnimation/Loading";
import { motion } from "framer-motion";


const SubCategories = ({ host , selectedLanguage }) => {
  const { category, id } = useParams();
  const [subcategories, setSubcategories] = useState([]);
  const [loading, setLoading] = useState(true);
  
  const product = subcategories.find((item) => item.id === id);
    
    useEffect(()=>{
        window.scrollTo(0,0)
    },[product])


  useEffect(() => {
    setLoading(true)
    const apiUrl = `${host}/api/v1/subcategories/${selectedLanguage}`;
    axios.get(apiUrl)
      .then(response => {
        const filteredSubcategories = response.data.filter(subcategory => {
          return subcategory.category === parseInt(id);
        });
        setSubcategories(filteredSubcategories);
        setLoading(false)
      })
      .catch(error => {
        console.error("Error fetching SubCategories data:", error);
        setLoading(false);
      });
  }, [id, selectedLanguage]);

  return (
    <>
      {
        loading ? <Loading/> :
          <section  className="containerSubCategories">
            <div className="list-title">
            <h1>ԸՆՏՐԵՔ ՍՐԱՀԸ</h1>
            </div>
            <div className="subCategoryList">
          {subcategories
            .filter(item => item.is_published)
            .map((item, index) => (
              <Link to={`/othersubcategories/${item.id}`} key={item.id}>
                <motion.div className="infoLinkConter"
                  initial={{opacity: 0, scale: 0.9}}
                  whileInView={{opacity: 1,scale:1}}
                  transition={{duration: 0.6,delay: 0.1*index,ease: 'easeInOut'}}
                  viewport={{once: true}}
                >
                  <div className="LinkBox">
                    <img src={item.image} alt="image" />
                  </div>
                  <h3>{item.sub_name}</h3>
                </motion.div>
              </Link>
            ))}
            </div>
          </section>
      }
    </>
  );
};

export default SubCategories;
