import React, { useEffect } from "react";
import Helmet from "../Components/Helmet/Helmet";
import "../styles/home.css";
import Services from "../services/Services";
import SliderCarousel from "../Components/Carousel/Carousel";

const Home = ({ selectedLanguage, pageData, host }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="home-container">
      <div className="intro_container">
        <SliderCarousel />
      </div>
      <div className="list-title">
        <h1>ԿԱՏԱՐԵԼ ԳՆՈՒՄՆԵՐ</h1>
      </div>
      <Services host={host} selectedLanguage={selectedLanguage} />

      <div className="list-title">
        <h1>ՄԵՐ ՄԱՍԻՆ</h1>
      </div>
      <div className="about_artWork">
        <span>
          <img src={require("../assets/images/border.png")} />
        </span>

        <section className="abtusimg">
          <img src={require("../assets/images/logo.png")} alt="logo" />
        </section>

        <section className="aboutTxt">
        ARTWORK - թարքմանաբար նշանակում է ( արվեսի գործ ),
ArtWork -ի գերագույն նպատակը այն է որ իր հաճախորդներին մատուցի որակյալ, գեղեցիկ և հարմարավետ կահույք,
ArtWork -ի մասնագետները ունեն շատ բարձր որակավորում և իրենց գործը կատարում են հաճույքով ինչի արդյունքում էլ մենք մեր
հաճախորդներին մատուցում ենք արվեստի գործեր, 
ArtWork -ը յուրաքանչուր հաճախորդի ցուցաբերում է անհատական մոտեցում,
ArtWork - ից օգտվելը հնարաորինս հեշտացրած է, մենք փորցում ենք հաճախորդին հնարավորինս քիչ նեղություն տալ,
պատվերները միշտ հասցնում ենք պայմանագրում նշված ժամկետից շուտ։
Այսքանը մեր մասին մախթում ենք հաճելի գնումներ
        </section>

        <span>
          <img src={require("../assets/images/border.png")} />
        </span>
      </div>
    </div>
  );
};

export default Home;
