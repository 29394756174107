import React, { useEffect, useState } from 'react';
import './Picker.css';
import am from '../../assets/images/am.png';
import ru from '../../assets/images/ru.png';
import en from '../../assets/images/us.png';

export default function Picker({changeLang}) {
  const [lang, setLang] = useState('am');
  const images = { am, ru, en };
  useEffect(() => {
    changeLang(lang)
  }, [lang])
  
  return (
    <>
    </>
    // <div className='lang-container'>
    //   <select onChange={(e) =>setLang(e.target.value)}>
    //     <option className='opt' value="am">AM</option>
    //     <option className='opt' value="ru">RU</option>
    //     <option className='opt' value="en">EN</option>
    //   </select>
    //   <div className="selected-lang">
    //     <img src={images[lang]} alt={lang} />
    //   </div>
    // </div>
  );
}